
.resume {
	max-width: 1000px;
	margin: 40px auto;
	
	.resume-heading {
		margin: 0 0 10px;
	}

	.download-resume {
		margin: 0 0 30px;
	}

	.columns {
		display: grid;
		gap: 30px;

		@media (min-width: $screen-sm) {
			grid-template-columns: 1fr 1fr;
			gap: 0 30px;

			> .column {
				&:first-child {
					grid-column: span 2;
				}
			}
		}
	}
	
	.panel-heading {
		padding-bottom: 10px;
		margin-top: 30px;
		margin-bottom: 20px;
		border-bottom: 1px solid #ddd;
	}

	.panel-content {
		.label {
			font-weight: 700;
		}
	}

	.job-heading,
	.education-heading {
		margin-bottom: 0;
	}

	.job-location-time,
	.education-location-time {
		margin-top: 0;
		color: #888;
		font-size: 15px;
		font-style: italic;
	}

	.contact-info {
		p {
			margin: 5px 0;
		}
	}
}


// --- Print Styles --- //

@media print {
	.page-wrap,
	.project-modal,
	.close-modal {
		display: none;
	}

	.resume-modal {
		opacity: 1;
		visibility: visible;
		position: static;
		height: auto;

		.modal-content {
			position: static;
			max-height: none;
			overflow: visible;
			margin: 0;
			padding: 0;
			width: auto;
			max-width: none;
		}
	}

	.resume {
		font-size: 12px;
		margin-top: 0;

		.panel-heading {
			padding-bottom: 6px;
			margin-top: 15px;
			margin-bottom: 10px;
		}

		.job-location-time,
		.education-location-time {
			font-size: inherit;
		}
	}
}
