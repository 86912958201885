
* {
	box-sizing: border-box;
}
*:before,
*:after {
	box-sizing: border-box;
}

html, body, .page-wrap, .site-main {
	height: 100%;
}

body {
	font-family: $font-family;
	font-size: 16px;
	line-height: 1.5;
	position: relative;
}

.page-wrap {
	position: relative;
	z-index: 2;
}

.container {
	max-width: 1300px;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: auto;
	margin-right: auto;
}

img {
	vertical-align: middle;
	display: block;
	max-width: 100%;
	width: auto;
	height: auto;
}

a {
	text-decoration: none;
	color: $blue;

	&.btn {
		display: inline-block;
		background-color: $blue;
		padding: 8px 20px;
		color: #fff;
		border-radius: 3px;
		transition: background-color .15s;

		&:hover {
			background-color: darken($blue, 15%);
		}
	}
}

// ---- Modals ---- //

body.modal-active,
body.resume-modal-active {
	overflow: hidden;
}

.modal {
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s, visibility .2s;
	position: fixed;
	z-index: $zindex-modal;
	top: 0; left: 0;
	width: 100%; height: 100%;
	background-color: rgba(0,0,0,0.7);

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.content {
		position: absolute;
		top: 0;
		left: 0; right: 0;
		max-height: calc(100% - 120px);
		overflow: scroll;
		margin: auto;
		margin-top: 8vh;
		width: calc(100% - 40px);
		max-width: 1350px;
		background-color: #fff;
		
		> .inner {
			padding: 60px 30px 30px;
			position: relative;
		}

		.modal-heading {
			text-align: center;
			margin-top: 0;
			margin-bottom: 30px;
		}

		textarea {
			height: 120px;

			@media (min-height: 900px){ height: 180px; }
			@media (min-height: 1000px){ height: 240px; }
		}

		.close-modal {
			position: absolute;
			top: 5px;
			right: 15px;
			font-size: 32px;
		}
	}
	&.resume-modal .content > .inner {
		padding-top: 30px;
	}

	.overview {
		margin: 30px 0 40px;
	}

	.site-image-large {
		box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
	}
}