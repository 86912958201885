
.site-main {
	.page-section.secondary {
		/* Background pattern downloaded from Subtle Patterns -- https://www.toptal.com/designers/subtlepatterns/y-so-serious-pattern/ */
		background-image: url(../images/y-so-serious.png);
		background-position: center top;
		background-size: cover;
		text-align: center;

		> .inner {
			padding: 20px;
			background-color: rgba(0, 65, 111, 0.8);
			color: #fff;
		}

		.selfie {
			border-radius: 50%;
			border: 5px solid #fff;
			box-shadow: 2px 2px 5px rgba(0,0,0,0.3);
			margin-left: auto;
			margin-right: auto;
			width: 180px;
			height: 180px;
			overflow: hidden;
			background-size: cover;
			background-position: center;

			@media (min-width: $screen-sm){
				padding-bottom: 100%;
				width: auto;
				height: 0;
			}
		}

		.name {
			margin-bottom: 5px;
		}

		.address {
			margin-bottom: 0;
		}

		.job-title {
			margin-top: 0;
		}

		a {
			color: #fff;
			text-decoration: underline;
		}
	}

	.page-section.primary {
		> .inner {
			padding: 20px;

			@media (min-width: $screen-lg){
				padding: 30px;
			}
		}
	}

	@media (min-width: $screen-sm){
		display: flex;

		.page-section.secondary {
			flex-grow: 0;
			flex-shrink: 0;
			flex-basis: 300px;
			width: 300px;

			> .inner {
				height: 100%;
				padding: 30px;
			}
		}

		.page-section.primary {
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: auto;

			> .inner {
				height: 100%;
				overflow: auto;
			}
		}
	}
}

.projects {
	> .inner {
		display: grid;
		grid-gap: 30px;
		grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
	}

	.project {
		> .inner {
			box-shadow: 0 0 3px rgba(0,0,0,0.5);
			height: 100%;
		}

		.content {
			padding: 20px;
			border-top: 2px solid #ddd;
		}

		.title {
			margin-top: 5px;
			margin-bottom: 10px;
		}

		.role {
			margin-top: 0;
			margin-bottom: 25px;
		}

		.modal-content {
			display: none;
		}
	}
}
.role-tag {
	display: inline-block;
	color: #fff;
	padding: 5px 10px;
	font-weight: 700;
	background-color: $orange;
	font-size: 0.75em;
	border-radius: 3px;
}