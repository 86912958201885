
.resume {
	.resume-heading {
		margin: 0 0 10px;
	}

	.download-resume {
		margin: 0 0 30px;
	}

	.tabs {
		padding: 0;
		margin: 0;
		border-bottom: 3px solid #444;
		overflow: hidden;

		.tab {
			list-style-type: none;
			border-bottom: 1px solid #444;

			@media (min-width: $screen-sm){
				float: left;
				border-bottom: 0;
				border-right: 2px solid #444;
			}
		}

		.tab-link {
			display: block;
			padding: 10px 15px;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 12px;
			transition: background-color .15s;
			user-select: none;

			&:hover {
				background-color: rgba(0,0,0,0.2);
			}

			@media (min-width: $screen-sm){
				padding: 14px;
				font-size: 13px;
			}
			@media (min-width: $screen-md){
				padding-left: 20px;
				padding-right: 20px;
				font-size: 14px;
			}
		}
		.tab.active .tab-link {
			background-color: $orange;
			color: #fff;
			cursor: default;
		}
	}

	.panel {
		display: none;
		padding: 15px 0;

		&.active {
			display: block;
		}

		.panel-heading {
			padding-bottom: 10px;
			margin-bottom: 40px;
			border-bottom: 1px solid #888;
		}

		.panel-content {
			max-width: 800px;

			.label {
				font-weight: 700;
			}
		}
	}

	.job-heading,
	.education-heading {
		margin-bottom: 0;
	}

	.job-location-time,
	.education-location-time {
		margin-top: 0;
		color: #888;
		font-size: 15px;
		font-style: italic;
	}
}
