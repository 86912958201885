
// ---- Colors ---- //

$blue: #0d62ce;
$orange: #ea6100;



// ---- Text ---- //

$font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;



// ---- Mobile Menu ---- //

$mobile-menu-width: 280px;
$mobile-menu-transition-duration: 0.2s;



// ---- Breakpoints ---- //

$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

$screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);



// ---- Z-Index ---- //

$zindex-dropdown:                      1000;
$zindex-popover:                       1060;
$zindex-tooltip:                       1070;
$zindex-modal-background:              1040;
$zindex-modal:                         1050;